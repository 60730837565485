import s from './Container.module.scss';

import React from 'react';

type Props = {
  children: JSX.Element,

};

const Container: React.FC<Props> = ({ children }) => {
  return (
    <div className={s.container}>
      {children}
    </div>
  );
};


export default Container;
