import s from './Copyright.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
    background?: string
}

const Copyright: React.FC<Props> = (props) => {
    const { t } = useTranslation();


    return (
        <div className={s.copyright} style={{backgroundColor: props.background}}>

            <a href="https://opendatabot.ua/c/43167505">
                <span>{t('footer.copyright')}</span>
            </a>
        </div>
    );
};


export default Copyright;