// const getIsLoggedIn = (state: { question: { isLoggedIn: any } }) => state.question.isLoggedIn;

const getUserAccess = (state: {
    auth: {
        accessToken: string
    }
}) => state.auth.accessToken;

const getUserInfo = (state: {
    auth: {
        user: {
            wallet: string,
            name: string,
            email: string,
            state: boolean
        }
    }
}) => state.auth.user;

const getWallet = (state: {
    auth: {
        user: {
            wallet: string
        }
    }
}) => state.auth.user.wallet;


const getUserState = (state: {
    auth: {
        user: {
            state: boolean
        }
    }
}) => state.auth.user.state;

const getConnected = (state: {
    auth: {
        connected: boolean
    }
}) => state.auth.connected

export {
    getUserAccess,
    getUserInfo, getWallet, getConnected, getUserState
}