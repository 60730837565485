import s from './LanguageBtn.module.scss';

import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import sprite from '../../../../assets/svg/sprite.svg';
// import i18next from 'i18next';
import '../../../../i18n';

// import AsyncSelect from 'react-select/async';


const lngs = [
  { code: 'En', name: 'en' },
  { code: 'Ua', name: 'ua' },
  { code: 'Ru', name: 'ru' },
];

interface IProp {
  border?: boolean,
  fontSize?: number
}

const LanguageBtn: React.FC<IProp> = ({ border = true, fontSize }) => {
  const [expand, setExpand] = useState<boolean>(false);
  const { i18n } = useTranslation();
  const { language, changeLanguage } = i18n;

  const currentLng = language.slice(0, 2);
  const filteredLng = lngs.filter(lng => currentLng !== lng.name)

  const arrowToggle = () => {
    setExpand(prevState => !prevState);
  };

  const handleChange = (event: any) => {
    let { id } = event.target;
    if (id === "") id = event.target.parentNode.parentNode.id;
    setExpand(false);
    return changeLanguage(id);
  };

  return (
    <>
      <div className={`${expand ? s.activeCloseBlock : ''}`} onClick={handleChange}></div>
      <div className={`${s.button} ${expand ? s.background : ''} ${border ? s.bordered : ''}`}>
        <div className={s.title} onClick={arrowToggle}>
          <svg className={s.activeFlag}>
            <use href={sprite + `#icon-flag-${currentLng === "ru" ? "ua" : currentLng}`} />
          </svg>
          <p className={s.activeLanguage} style={{ fontSize: `${fontSize}px` }}>{currentLng}</p>
          <svg className={`${expand ? s.open : ''} ${s.downBtn}`}>
            <use href={sprite + '#icon-down'} />
          </svg>
        </div>
        <ul className={`${expand ? s.active : ''} ${s.languageList}`}>
          {filteredLng.map(({ code, name }) => (
            <li key={code} onClick={handleChange} id={name} className={s.language} style={{ fontSize: `${fontSize}px` }}>
              <svg className={s.flag}>
                <use href={sprite + `#icon-flag-${name === "ru" ? "ua" : name}`} />
              </svg>
              {code}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default LanguageBtn;
