import s from "./MobileMenuBtn.module.scss";

import React from 'react';
import sprite from '../../../../assets/svg/sprite.svg';

interface Props {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const MobileMenu: React.FC<Props> = ({ onClick }) => {
  return (
    <button
      className={s.btn}
      aria-expanded="false"
      aria-controls="mobile-menu"
      type="button"
      onClick={onClick}
      aria-label="open menu"
    >
      <svg>
        <use href={sprite + '#icon-burger'} />
      </svg>
    </button>
  );
};

export default MobileMenu;
