import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';


const getMembersOperation = createAsyncThunk(`api/teams`, async () => {
    try {
        const { data } = await axios.get(`api/teams`);

        return data;
    } catch (error: any) {
        console.error(error.message);
    }
});


export { getMembersOperation };
