import { createSlice } from '@reduxjs/toolkit';
import { signIn, signOut, getUser, refreshToken } from './auth-operation';
// import { IconSuccess, TextSuccess } from 'components/shared/toasts/Toasts';
// import { toast, ToastContainer } from 'react-toastify';

const initialState = {
    accessToken: null,
    user: {
        wallet: "",
        name: "",
        email: "",
        state: false,
    },
    connected: false
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
    },

    extraReducers: builder => {
        builder.addCase(signIn.fulfilled, (state, action) => {
            state.accessToken = action.payload.accessToken;
            state.connected = true;
          //  toast.success(<TextSuccess />, { icon: IconSuccess });
        });
        builder.addCase(signOut.fulfilled, (state, action) => {
            state.accessToken = null;
            state.user = {
                wallet: "",
                name: "",
                email: "",
                state: false
            }
            state.connected = false;
           // toast.success(<TextSuccess />, { icon: IconSuccess });
        });
        builder.addCase(getUser.fulfilled, (state, action) => {
            state.user = action.payload;
            // state.connected = true;
         //   toast.success(<TextSuccess />, { icon: IconSuccess });
        });
        builder.addCase(refreshToken.fulfilled, (state, action) => {
            state.accessToken = action.payload.accessToken;
            // state.connected = true;
          //  toast.success(<TextSuccess />, { icon: IconSuccess });
        });
       // <ToastContainer/>
    },
});
export default authSlice.reducer;
