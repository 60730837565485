import { createSlice } from '@reduxjs/toolkit';
import { getMembersOperation } from './team-operation';

const initialState = {
  teams: [
    {
      department: "",
      members: [{
        _id: "",
        name: "",
        department: "",
        role: {
          data: "",
          svg: ""
        },
        position: "",
        linkedin: "",
        twitter: ""
      }]
    }
  ]
};

const teamSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder.addCase(getMembersOperation.fulfilled, (state, action) => {
      // state.teams = action.payload;
      state.teams = action.payload !== undefined ? action.payload : state.teams;
    });
  },
});

export default teamSlice.reducer;
