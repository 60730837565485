import s from './ConnectWalletBtn.module.scss';

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useConnector from './hooks/useConnector';

import sprite from '../../../assets/svg/sprite.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'store/auth/auth-operation';
import { getConnected, getUserInfo } from 'store/auth/auth-selectors';

interface Props {
  border?: boolean,
}

const ConnectWalletButton: React.FC<Props> = ({ border = false }) => {
  const { t } = useTranslation();
  const { open, disconnect } = useConnector();

  const dispatch = useDispatch<any>();
  const user = useSelector(getUserInfo);
  const connected = useSelector(getConnected);

  const handleClickEvent = () => {
    return !connected ? open() : disconnect()
  }

  const minifyWalletString = () => {
    const firstPart = `${user.wallet}`.slice(0, 5);
    const lastPart = `${user.wallet}`.slice(`${user.wallet}`.length - 4, `${user.wallet}`.length);
    return (firstPart + '...' + lastPart).toLocaleUpperCase()
  }

  useEffect(() => {
    if (connected) {
      dispatch(getUser(null));
    }
  }, [connected]);

  return (
    <button
      className={`
      ${s.btn}
      ${connected ? (user?.state ? s.connectedHolder : s.connected) : s.disconnected}
      ${border ? s.bordered : ''}
      `}
      onClick={handleClickEvent}
    >
      <svg className={s.iconShield}>
        <use href={sprite + '#icon-shield'} />
      </svg>
      <span className={s.text}>
        {!connected
          ? t('navi.connect-wallet')
          : minifyWalletString()
        }
      </span>
    </button>
  );
};

export default ConnectWalletButton;