import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '@reduxjs/toolkit/dist/query/core/apiState';
// import { toast } from 'react-toastify';
// import { IconError } from '../../Toast/Toast';


const getVouchersOperation = createAsyncThunk<any, any, any>(
  `api/vouchers`,
  async (_, thunkAPI) => {
    try {
      const {vouchers} = thunkAPI.getState() as any;
      const {coins, price, page, limit} = vouchers;

      const { data } = await axios.get(
        `api/vouchers?page=${page}` +
        `&limit=${limit}` +
        `&toCurrency=${coins.join(",")}` +
        `&value=${price.join(",")}`
      );

      return data;
    } catch (error: any) {
      console.error(error.message);
    }
  });

const getVouchersCategoryOperation = createAsyncThunk<any, any, any>(
  `api/vouchers/category`,
  async () => {
    try {
      const { data } = await axios.get(`api/vouchers/category`);

      return data;
    } catch (error: any) {
      console.error(error.message);
    }
  });

export { getVouchersOperation, getVouchersCategoryOperation };
