
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
// import { toast } from 'react-toastify';
// import { IconError } from '../../Toast/Toast';

const getProductsOperation = createAsyncThunk<any, any, any>(`api/products`, async (cred) => {
    try {
        const { data } = await axios.get(`api/products?page=${cred.page}&limit=${cred.limit}`);

        return data;
    } catch (error: any) {
        console.error(error.message);
    }
});

export { getProductsOperation };
