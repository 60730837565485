import type { ConfigOptions } from '@web3modal/core';

export const config: ConfigOptions = {
  projectId: `${process.env.REACT_APP_WALLET_MODAL_API_KEY}`,
  theme: 'dark',
  accentColor: 'blue',
  ethereum: {
    appName: 'Web3Forces',
  },
};
