import s from "./ServiceLinks.module.scss";

// import React from 'react';

import ServiceItem from "./serviceItem/ServiceItem";
import { useTranslation } from 'react-i18next';


// interface IProps {

// }

const ServiceLinks = () => {
  const { t } = useTranslation();
  return (
    <ul className={s.list} >
      <ServiceItem
        href="/soon"
        text={t('topheader.service')}
      />
      <ServiceItem
        href="/soon"
        text={t('topheader.faq')}
      />
      <ServiceItem
        href="https://t.me/w3f_support_bot"
        text={t('topheader.support')}
      />
    </ul>
  );
};

export default ServiceLinks;
