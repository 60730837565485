import { createSlice } from '@reduxjs/toolkit';
import { string } from 'yup/lib/locale';
import { getVouchersOperation, getVouchersCategoryOperation } from './vouchers-operation';
// import { IconSuccess, TextSuccess } from 'components/shared/toasts/Toasts';
// import { toast, ToastContainer} from 'react-toastify';

interface Voucher {
  _id: string,
  fromCurrency: string,
  toCurrency: string,
  url: string,
  value: number,
  visual: {
    color: string,
    svg: string
  }
}

const initialState = {
  vouchers: [] as Voucher[],
  category: {
    toCurrency: [] as string[],
    values: [] as number[],
  },
  page: 1,
  limit: 0,
  coins: [] as string[],
  price: [] as string[],
  isLoading: false,
  // user: { email: 'null', name: 'null', question: 'null' },
  // isLoggedIn: false,
};

const vouchersSlice = createSlice({
  name: 'vouchers',
  initialState,
  reducers: {
    nextPage: (state) => {
      state.page++;
    },
    setLimit: (state, action) => {
      state.page = 1;
      state.limit = action.payload;
    },
    setPriceFilter: (state, action) => {
      state.page = 1;
      state.vouchers = [];

      if (state.price.indexOf(action.payload) > -1) {
        state.price = state.price.filter((item: string) => item !== action.payload);
      } else {
        state.price.push(action.payload);
      }
    },
    setCoinFilter: (state, action) => {
      state.page = 1;
      state.vouchers = [];

      if (state.coins.indexOf(action.payload) > -1) {
        state.coins = state.coins.filter((item: string) => item !== action.payload);
      } else {
        state.coins.push(action.payload);
      }
    },
    resetPriceFilter: (state) => {
      state.price = [];
      state.vouchers = [];
    },
    resetCoinsFilter: (state) => {
      state.coins = [];
      state.vouchers = [];
    },
  },

  extraReducers: builder => {
    builder.addCase(getVouchersOperation.fulfilled, (state, action) => {
      state.vouchers = action.payload !== undefined ? action.payload : state.vouchers;
      state.isLoading = false;
      // state.isLoggedIn = true;
      // toast.success(<TextSuccess />, { icon: IconSuccess });
    });
    builder.addCase(getVouchersOperation.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getVouchersCategoryOperation.fulfilled, (state, action) => {
      // state.category = action.payload;
      state.category = action.payload !== undefined ? action.payload : state.category;

      // state.isLoggedIn = true;
      // toast.success(<TextSuccess />, { icon: IconSuccess });
    });
  },
});

export const {
  setCoinFilter,
  resetCoinsFilter,
  setPriceFilter,
  resetPriceFilter,
  nextPage,
  setLimit,
} = vouchersSlice.actions;
export default vouchersSlice.reducer;
