import s from './Dropdown.module.scss';

import React, { useState } from 'react';
import { NavLink, Navigate } from 'react-router-dom';
import sprite from '../../../assets/svg/sprite.svg';
import { useTranslation } from 'react-i18next';

interface IDropdownProps {
  dropdownTitle: string;
}

const Dropdown: React.FC<IDropdownProps> = ({ dropdownTitle }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(prevState => !prevState);

  const Title = () => {
    return (
      <>
        <div className={s.title} onClick={handleClick}>
          <p className={s.titleText}>{dropdownTitle}</p>
          <svg className={`${s.svg} ${open ? s.open : ''}`}>
            <use href={sprite + '#icon-down'} />
          </svg>
        </div>
      </>
    );
  };

  const { t } = useTranslation();

  return (
    <div>
      <Title />
      {open && (
        <>
          <div
            style={{ width: '100%', height: '100vh', position: 'fixed', left: 0, top: 0 }}
            onClick={handleClick}
          ></div>
          <ul className={`${s.list} ${open ? s.background : ''}`}>
            <li className={s.item}>
              <Title />
            </li>
            <li className={s.item} onClick={handleClick}>
              <NavLink to="/soon">{t('navi.dropdown.automation')}</NavLink>
            </li>
            <li className={s.item} onClick={handleClick}>
              <NavLink to="/soon">{t('navi.dropdown.vouchers')}</NavLink>
            </li>
            <li className={s.item} onClick={handleClick}>
              <a href="http://web3forces.store">{t('navi.dropdown.shop')}</a>
            </li>
          </ul>
        </>
      )}
    </div>
  );
};

export default Dropdown;
