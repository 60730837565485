import s from "./Logo.module.scss";

import React from 'react';

import { Link } from 'react-router-dom';
import sprite from '../../../assets/svg/sprite.svg';

interface IProp {
  onClick?: () => void;
}

const Logo: React.FC<IProp> = ({ onClick }) => {
  return (
    <Link className={s.link} to="/">
      <svg>
        <use href={sprite + '#icon-logo'} />
      </svg>
      <span>Web3forces</span>
    </Link>
  );
};

export default Logo;
