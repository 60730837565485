
import sprite from '../../../../assets/svg/sprite.svg';
import a from 'animate.css';
import s from "./Preloader.module.scss"

const Preloader = () => {
  return (
    <div className={s.loading}>
      <div className={s.after}>
        <div className={s.container}>
          <svg className={[s.iconTop, a.animated, a.backInDown].join(' ')}>
            <use href={sprite + '#logo-top'}></use>
          </svg>
          <svg className={[s.iconBottom, a.animated, a.backInUp].join(' ')} >
            <use href={sprite + '#logo-bottom'}></use>
          </svg>
        </div>
      </div>
    </div>
  );
};


export default Preloader;
