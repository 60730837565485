import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  shop: [
    {
      _id: '',
      image: '',
      name: '',
      size: '',
      color: '',
      type: '',
      price: 0,
      count: 1,
    },
  ],
  quantity: 0,
  total: 0,
  tshirtsBasketModal: false
};

const basketTshirts = createSlice({
  name: 'basketTshirts',
  initialState,
  reducers: {
    openModal: (state) => {
      state.tshirtsBasketModal = true;
    },
    closeModal: (state) => {
      state.tshirtsBasketModal = false;
    },
    addToBasket: (state, action) => {
      const countAction = { ...action.payload };

      !state.shop[0]?._id || state.shop.includes(action.payload._id)
        ? (state.shop = [countAction])
        : (state.shop = [...state.shop, countAction]);

      state.quantity = state.shop.length;
      // if(state.shop.length === 1) state.tshirtsBasketModal = true;
    },
    removeFromBasket: (state, { payload }) => {
      state.shop = state.shop.filter(item => item._id !== payload);

      state.quantity = state.shop.length;
      // if(!state.shop.length) state.tshirtsBasketModal = false;
    },
    increaseQuantity: (state, action) => {
      const { payload: { itemId, quantityToAdd } } = action;
      state.shop = state.shop.map(item =>
        item._id === itemId
          ? {
              ...item,
              count: item.count + quantityToAdd,
            }
          : item
      );
    },
    decreaseQuantity: (state, { payload }) => {
      state.shop = state.shop.filter(item =>
        item._id === payload
          ? {
              ...item,
              count: (item.count -= 1),
            }
          : item,
      );
    },
    changeSize: (state, action) => {
      const { payload: { itemId, newSize } } = action;
      state.shop = state.shop.map(item =>
        item._id === itemId
          ? {
              ...item,
              size: newSize,
            }
          : item,
      );
    },
  },
});

export const {
  openModal,
  closeModal,
  addToBasket,
  removeFromBasket,
  increaseQuantity,
  decreaseQuantity,
  changeSize
} = basketTshirts.actions;

export default basketTshirts.reducer;
