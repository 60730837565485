import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
// import { toast } from 'react-toastify';
// import { IconError } from '../../Toast/Toast';


const signIn = createAsyncThunk<any, any, any>(`api/auth/signin`, async (wallet) => {
    try {
        const { data } = await axios.post(`api/auth/signIn`, { wallet });

        return data;
    } catch (error: any) {
        console.error(error.message);
    }
});

const signOut = createAsyncThunk<any, any, any>(`api/auth/signout`, async () => {
    try {
        const { data } = await axios.get(`api/auth/signOut`);

        return data;
    } catch (error: any) {
        console.error(error.message);
    }
});

const refreshToken = createAsyncThunk<any, any, any>(`api/auth/refresh`, async () => {
    try {
        const { data } = await axios.get(`api/auth/refresh`);

        return data;
    } catch (error: any) {
        console.error(error.message);
    }
});

const getUser = createAsyncThunk<any, any, any>(`api/user`, async (_, thunkAPI) => {
    try {
        const { data } = await axios.get(`api/user`);

        return data;
    } catch (error: any) {
        if (error.code === 429) {
            return console.error(error.message);
        }
        // const { auth } = thunkAPI.getState() as any
        // if (auth.connected) {
        //     thunkAPI.dispatch(refreshToken(null))
        //     thunkAPI.dispatch(getUser(null))
        // }
        // console.error(error.message);
    }
});

export { signIn, signOut, refreshToken, getUser };
