import s from './Header.module.scss';

import { useState, useEffect } from 'react';

import Container from 'components/shared/container/Container';

import Logo from 'components/shared/logo/Logo';
import Navigation from 'components/shared/navigation/Navigation';
import SocialIcons from 'components/shared/icons/SocialIcons';
import LanguageBtn from 'components/shared/buttons/languageBtn/LanguageBtn';
import ServiceItem from 'components/shared/serviceLinks/serviceItem/ServiceItem';

import ConnectWalletBtn from 'components/widgets/connectWalletBtn/ConnectWalletBtn';
import MobileMenuBtn from 'components/shared/buttons/mobileMenuBtn/MobileMenuBtn';
import MobileMenu from 'components/shared/modals/mobileMenuFix/MobileMenu';

import { useTranslation } from 'react-i18next';

const Header = () => {
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [headerWalletButton, setHeaderWalletButton] = useState<null | boolean>(null);

  const handleOpenMenuEvent = () => {
    setOpenMenu(true);
  };

  const handleCloseMenuEvent = () => {
    setOpenMenu(false);
  };

  const handleResizeScreen = () => {
    if (window.innerWidth < 768) {
      return setHeaderWalletButton(false);
    }

    return setHeaderWalletButton(true);
  }

  useEffect(() => {
    handleResizeScreen();
    window.addEventListener('resize', handleResizeScreen);

    return () => {
      window.removeEventListener('resize', handleResizeScreen);
    }
  }, []);

  return (
    <>
      <div className={s.serviceSection}>
        <Container>
          <div className={s.wrapper}>
            <div className={s.left_side}>
              <div className={s.socialIcons}>
                <SocialIcons />
              </div>
              <div className={s.connectWallet}>
                {headerWalletButton !== null && !headerWalletButton && <ConnectWalletBtn />}
              </div>
            </div>
            <div className={s.right_side}>
              <div className={s.langBtn}>
                <LanguageBtn />
              </div>
              <div className={s.serviceLinks}>
                <ServiceItem
                  href="https://t.me/w3f_support_bot"
                  text={t('topheader.support')}
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
      <header className={s.header}>
        <Container>
          <div className={s.wrapper}>
            <Logo />
            <div className={s.mobileMenu}>
              <MobileMenuBtn onClick={handleOpenMenuEvent} />
              <MobileMenu open={openMenu} onClose={handleCloseMenuEvent} />
            </div>
            <div className={s.navLinks}>
              <Navigation />
            </div>
            <div className={s.connectWallet}>
              {headerWalletButton !== null && headerWalletButton && <ConnectWalletBtn border={true} />}
            </div>
          </div>
        </Container>
      </header>
    </>
  );
};

export default Header;
