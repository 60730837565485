import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    modalActiveItem: {}
}

const funds = createSlice({
    name: 'funds',
    initialState,
    reducers: {
        modalActiveItem: (state, action) => {
            state.modalActiveItem = action.payload
        },
    }
});

export const {
    modalActiveItem
} = funds.actions;

export default funds.reducer;