import s from "./Navigation.module.scss";

import React from 'react';

// import NavigationElement from '../NavigationElement/NavigationElement';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import Dropdown from '../dropdown/Dropdown';


const Navigation = () => {
  const { t } = useTranslation();

  return (
    <ul className={s.navList}>
      <li className={s.item}>
        <NavLink className={s.link} to={"/"} >
          {t('navi.about')}
        </NavLink>
      </li>
      <li className={s.item}>
        <div className={s.dropdown}>
          <Dropdown dropdownTitle={t('navi.products')} />
        </div>
      </li>
      <li className={s.item}>
        <NavLink to="/soon">
          {t('navi.academia')}
        </NavLink>
      </li>
      <li className={s.item}>
        <NavLink to="/soon">
          {t('navi.contacts')}
        </NavLink>
      </li>
      <li className={s.item}>
        <NavLink to="/funds">
          {t('navi.funds')}
        </NavLink>
      </li>
    </ul>
  );
};

export default Navigation;
