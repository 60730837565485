import s from "./ServiceItem.module.scss";

import React from 'react';

interface IProps {
  text: string;
  href: string;
}

const ServiceItem: React.FC<IProps> = ({ text, href }) => {
  return (
    <li className={s.item} >
      <a className={s.link} href={href}> {text} </a>
    </li>
  );
};

export default ServiceItem;
