import s from './Footer.module.scss';

import { useTranslation } from 'react-i18next';

import Logo from 'components/shared/logo/Logo';
// import Navigation from "../Navigation/Navigation"
import LanguageBtn from 'components/shared/buttons/languageBtn/LanguageBtn';
import ServiceLinks from 'components/shared/serviceLinks/ServiceLinks';
import SocialIcons from 'components/shared/icons/SocialIcons';
// import FooterNavigation from './nav/FooterNav'
import Navigation from 'components/shared/navigation/Navigation';
import Container from 'components/shared/container/Container';

import sprite from 'assets/svg/sprite.svg';


const Footer = (props: any) => {
  const { t } = useTranslation();
  return (
    <footer className={s.footer} style={{ backgroundColor: props.background }}>
      <Container>
        <>
          <div className={s.wrapper}>
            <div className={s.content}>
              <div className={s.left}>
                <Logo />
                <p className={s.subtitle}>
                  {t('footer.blochain')} <br /> {t('footer.made-in')}
                  <svg className={s.footerFlag}>
                    <use href={sprite + '#icon-flag-ua'} />
                  </svg>
                </p>
              </div>
              <div className={s.right}>
                <div className={s.socialIcons}>
                  <SocialIcons />
                </div>
                <div className={s.navigation}>
                  <Navigation />
                </div>

                <div>
                  <div className={s.langBtn}>
                    <LanguageBtn />
                  </div>
                  <div className={s.services}>
                    <ServiceLinks />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </Container >
    </footer>

  );
};

export default Footer;
