import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
  persistStore,
  persistReducer,
  PersistConfig,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import authReducer from './auth/auth-slice';
import teamReducer from './team/team-slice'
import basketReducer from './basket/basket-slice';
import basketTshirtsReducer from './basketTshirts/basketTshirts-slice';
// import questionReducer from './question/question-slice';
import vouchersReducer from './vouchers/vouchers-slice';
import tshirtsReducer from './shop/shop-slice';
import productsReducer from './products/products-slice';
import academyReducer from './academy/academy-slice';
import fundsReducer from './funds/funds-slice';

const authPersistConfig = {
  key: 'auth',
  storage,
  // whitelist: ['token'],
};

// const persistConfig: PersistConfig<any, any, any, any> = {
//   key: 'question ',
//   storage,
//   // whitelist: ['accessToken'],
// };

const persistTeam: PersistConfig<any, any, any, any> = {
  key: 'teams',
  storage
}

const persistBasket: PersistConfig<any, any, any, any> = {
  key: 'basket',
  storage,
};

const persistBasketTshirts: PersistConfig<any, any, any, any> = {
  key: 'basketTshirts',
  storage,
};

const persistVouchers: PersistConfig<any, any, any, any> = {
  key: 'vouchers',
  storage,
  blacklist: ['page', 'limit']
};

const persistTshirts: PersistConfig<any, any, any, any> = {
  key: 'shop',
  storage,
};

const persistProducts: PersistConfig<any, any, any, any> = {
  key: 'products',
  storage,
};

const persistAcademy: PersistConfig<any, any, any, any> = {
  key: 'academy',
  storage,
};

const persistFunds: PersistConfig<any, any, any, any> = {
  key: "funds",
  storage,
}

const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
];

const store = configureStore({
  reducer: {
    auth: persistReducer(authPersistConfig, authReducer),
    teams: persistReducer(persistTeam, teamReducer),
    basket: persistReducer(persistBasket, basketReducer),
    basketTshirts: persistReducer(persistBasketTshirts, basketTshirtsReducer),
    vouchers: persistReducer(persistVouchers, vouchersReducer),
    shop: persistReducer(persistTshirts, tshirtsReducer),
    products: persistReducer(persistProducts, productsReducer),
    academy: persistReducer(persistAcademy, academyReducer),
    funds: persistReducer(persistFunds, fundsReducer)
  },
  middleware,
  devTools: process.env.NODE_ENV === 'development',
});

const persistor = persistStore(store);

export { store, persistor };