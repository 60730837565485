import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  payNowModal: false,
  itemModal: false,
  modalActiveItem: {}
};

const tshirt = createSlice({
  name: 'tshirt',
  initialState,
  reducers: {
    openPayNowModal: (state) => {
      state.payNowModal = true;
    },
    closePayNowModal: (state) => {
      state.payNowModal = false;
    },

    openItemModal: (state) => {
      state.itemModal = true;
    },

    closeItemModal: (state) => {
      state.itemModal = false;
    },

    modalActiveItem: (state, action) => {
      state.modalActiveItem = action.payload
    },
  },
});

export const {
  openPayNowModal,
  closePayNowModal,
  openItemModal,
  closeItemModal,
  modalActiveItem
} = tshirt.actions;

export default tshirt.reducer;
