import s from './MobileMenu.module.scss';

import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import sprite from '../../../../assets/svg/sprite.svg';

import Container from '../../container/Container';
import Logo from '../../logo/Logo';
import ConnectWalletBtn from '../../../widgets/connectWalletBtn/ConnectWalletBtn';
import LanguageBtn from '../../buttons/languageBtn/LanguageBtn';
import SocialIcons from '../../icons/SocialIcons';

interface Props {
  open: boolean;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
}

const modalContainer = document.querySelector('#modal-root');
const modal: HTMLElement = modalContainer as HTMLElement;

const MobileMenu: React.FC<Props> = ({ open, onClose }) => {
  if (!open) return null;

  const { t } = useTranslation();
  const [openDropBox, setOpen] = useState(false);
  const handleClick = () =>
    setOpen(prevState => {
      return !prevState;
    });

  return ReactDOM.createPortal(
    <div className={s.wrapper} id="menu">
      <div className={s.сontainer}>
        <div className={s.header}>
          <Logo />
          <button className={s.btn} type="button" onClick={onClose} aria-label="close menu">
            <svg className={s.closeBtn}>
              <use href={sprite + '#close-modal'} />
            </svg>
          </button>
        </div>
      </div>
      <div>
        <div className={`${openDropBox ? s.activeCloseBlock : ''}`} onClick={handleClick}></div>
        <nav className={s.navigation}>
          <ul className={s.list}>
            <li className={s.item}>
              <NavLink to="/">
                <button onClick={onClose}>{t('navi.about')}</button>
              </NavLink>
            </li>

            <li className={s.item} onClick={handleClick}>
              {t('navi.products')}
              <svg className={`${s.downBtn} ${openDropBox ? s.open : ''}`}>
                <use href={sprite + '#icon-down'} />
              </svg>

              <ul className={openDropBox ? s.openDropBox : s.closeDropBox}>
                <li className={s.item}>
                  <NavLink to="/soon">
                    <button onClick={onClose}>{t('navi.dropdown.automation')}</button>
                  </NavLink>
                </li>
                <li className={s.item}>
                  <NavLink to="/soon">
                    <button onClick={onClose}>{t('navi.dropdown.vouchers')}</button>
                  </NavLink>
                </li>

                <li className={s.item}>
                  <a href="http://web3forces.store">
                    <button onClick={onClose}>{t('navi.dropdown.shop')}</button>
                  </a>
                </li>
              </ul>
            </li>

            <li className={s.item}>
              <NavLink to="/soon">{t('navi.academia')}</NavLink>
            </li>

            <li className={s.item}>
              <NavLink to="/soon">{t('navi.contacts')}</NavLink>
            </li>

            <li className={s.item}>
              <NavLink to="/funds">{t('navi.funds')}</NavLink>
            </li>
          </ul>
        </nav>

        <div className={s.connectWalletSection}>
          <ConnectWalletBtn border={true} />
        </div>
        <div className={s.langBtn}>
          <LanguageBtn fontSize={16} />
        </div>
      </div>
      <div className={s.socialIcons}>
        <SocialIcons />
      </div>
    </div>,
    modal,
  );
};

export default MobileMenu;
