import { useState, useEffect } from 'react';

import { useConnectModal, useAccount, useDisconnect } from '@web3modal/react';
import { useDispatch, useSelector } from 'react-redux';
import { signIn, getUser, signOut } from 'store/auth/auth-operation';
import { getConnected } from 'store/auth/auth-selectors';

const useConnector = () => {
  const [prevStatus, setPrevStatus] = useState<string>('');
  const dispatch = useDispatch<any>();
  const signedIn = useSelector(getConnected);

  const {isOpen, open, close} = useConnectModal();
  const disconnectEvent = useDisconnect();
  const {account : {address, isConnected, status}, isReady} = useAccount();

  const disconnect = () => {
    disconnectEvent();
    dispatch(signOut(null));
  }

  useEffect(() => {
    if(status){
      setPrevStatus(status);
    }

    if(prevStatus === 'connected' && status === 'disconnected'){
      disconnect();
    }
  },[status]);

  useEffect(() => {
    if(isConnected && !signedIn){
      dispatch(signIn(address));
    }
  },[isConnected]);

  return {
    open,
    disconnect
  };
};

export default useConnector;
