import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  vouchers: [
    {
      _id: '',
      fromCurrency: '',
      toCurrency: '',
      url: '',
      value: '',
      visual: {
        color: '',
        svg: '',
      },
      count: 1,
    },
  ],
  quantity: 0,
  total: 0,
  modal: false
};

const basket = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    openModal: (state) => {
      state.modal = true;
    },
    closeModal: (state) => {
      state.modal = false;
    },
    addToBasket: (state, action) => {
      const countAction = { ...action.payload, count: 1 };

      !state.vouchers[0]?._id || state.vouchers.includes(action.payload._id)
        ? (state.vouchers = [countAction])
        : (state.vouchers = [...state.vouchers, countAction]);

      state.quantity = state.vouchers.length;
      if(state.vouchers.length === 1) state.modal = true;
    },
    removeFromBasket: (state, { payload }) => {
      state.vouchers = state.vouchers.filter(item => item._id !== payload);

      state.quantity = state.vouchers.length;
      if(!state.vouchers.length) state.modal = false;
    },
    increaseQuantity: (state, action) => {
      state.vouchers = state.vouchers.filter(item =>
        item._id === action.payload
          ? {
              ...item,
              count: (item.count += 1),
            }
          : item,
      );
    },
    decreaseQuantity: (state, { payload }) => {
      state.vouchers = state.vouchers.filter(item =>
        item._id === payload
          ? {
              ...item,
              count: (item.count -= 1),
            }
          : item,
      );
    },
  },
});

export const {
  openModal,
  closeModal,
  addToBasket,
  removeFromBasket,
  increaseQuantity,
  decreaseQuantity
} = basket.actions;

export default basket.reducer;
