import { createSlice } from '@reduxjs/toolkit';
import { getAcademyOperation } from './academy-operation';
// import { IconSuccess, TextSuccess } from 'components/shared/toasts/Toasts';
// import { toast, ToastContainer } from 'react-toastify';

const initialState = {
    academy: [
        {
            _id: "",
            title: "",
            desc: "",
            img: "",
            url: ""
        }
    ],
};

const academySlice = createSlice({
    name: 'academy',
    initialState,
    reducers: {},

    extraReducers: builder => {
        builder.addCase(getAcademyOperation.fulfilled, (state, action) => {
            // state.academy = action.payload;
            state.academy = action.payload !== undefined ? action.payload : state.academy;
            // state.isLoggedIn = true;
            //  toast.success(<TextSuccess />, { icon: IconSuccess });
            // <ToastContainer/>
        });
    },
});
export default academySlice.reducer;
